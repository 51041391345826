import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['writingTiers', 'addOns', 'outlineType', 'bundleTier', 'managedServiceTiers',
    'qualityAssurance', 'qualityControl', 'articleCount', 'managedServiceTiersContainer', 'useAi', 'wordCount', 'wordCountLabel']

  connect() {
    this.triggerNameUpdate()
  }

  disconnect() {
    this.triggerNameUpdate()
    this.triggerPriceUpdate()
  }

  triggerNameUpdate() {
    const event = new CustomEvent('postingCountChange')
    window.dispatchEvent(event)
  }

  triggerPriceUpdate() {
    const event = new CustomEvent('postingRemoved')
    window.dispatchEvent(event)
  }

  bundleTierChange() {
    if (event.target.value == 'Ultimate Fiction') {
      this.writingTiersTarget.querySelector('option[value="Finance and Technology"]').remove();
    } else {
      if (!this.writingTiersTarget.querySelector('option[value="Finance and Technology"]')) {
        const item = this.writingTiersTarget.firstElementChild.cloneNode(true);
        item.innerText = 'Finance and Technology';
        item.value = 'Finance and Technology';
        this.writingTiersTarget.append(item);
      }
    }

    this.updateOutlineType()
  }

  writingTierChange() {
    this.updateOutlineType()
  }

  /*
  Change the Outline type
  */
  updateOutlineType() {
    switch (this.bundleTierTarget.value) {
      case 'Platinum':
      case 'Audiobook Narration':
        // Only the `Fiction` tier gives the customer a choice of plot outlines.
        //  All other tiers require Pro plot outline
        this.hideCoverField(false)
        switch (this.writingTiersTarget.value) {
          case 'Fiction':
            this.outlineTypeTarget.disabled = false;
            break;
          default:
            this.outlineTypeTarget.disabled = false;
            this.outlineTypeTarget.value = 'Professional Outline';
            break;
        }
        break;
      case 'Gold':
      case 'Silver':
        // Outlines are not included, so hide the entire option
        this.outlineTypeTarget.disabled = true;
        this.hideCoverField(true)
        break;
      case 'Ultimate Fiction':
        this.hideCoverField(false);
        this.outlineTypeTarget.value = 'Plot Outline';
        this.outlineTypeTarget.disabled = true;
        break;
      default:
        this.hideCoverField(false)
        this.outlineTypeTarget.disabled = false;
        break;
    }
  }

  hideCoverField(hidden=true) {
    let coverInput = this.outlineTypeTarget;
    let coverDiv = coverInput.parentElement;

    coverDiv.hidden = hidden === true;
  }


  coverPackageChange() {
    if (event.target.value === 'Platinum') {
      this.addOnsTarget.checked = true
      this.addOnsTarget.disabled = true
    } else {
      this.addOnsTarget.checked = false
      this.addOnsTarget.disabled = false
    }
  }

  /*
    called in views/job_posting/cost_calculator/_posting_fields

    If user chooses Turnkey (service tier 3), require QA/QC
    Disabled fields are not submitted in forms, so selecting the turnkey tier
    adds QA and QC in the calculation stage.
  */
  managedServicesChange(event) {
    if(event.target.value === '3') {
      this.qualityAssuranceTarget.disabled = true
      this.qualityAssuranceTarget.value = 'true'
      this.qualityControlTarget.disabled = true
      this.qualityControlTarget.value = 'true'
    } else {
      this.qualityAssuranceTarget.disabled = false
      this.qualityControlTarget.disabled = false
    }
  }

  /*
    Monitor Article count to enable/disable managed-services tier
   */
  articleCountChange(event) {
    const articleCount = event.target.value;

    if (articleCount < 10) {
      this.managedServiceTiersContainerTarget.classList.add('d-none')
      this.managedServiceTiersTarget.value = '0'
      this.managedServicesChange({ target: { value: '0' } })
    } else {
      this.managedServiceTiersContainerTarget.classList.remove('d-none')
    }
  }

  aiGeneratedChange(event) {
    if (event.target.value === 'true') {
      this.writingTiersTarget.querySelector('option[value="Finance and Technology"]').remove();
      this.wordCountTarget.min = 10000;
      if (this.wordCountTarget.value < 10000) {
        this.wordCountTarget.value = 10000;
      }
      this.wordCountLabelTarget.innerText = 'Word Count (Minimum: 10000, Increments of: 500)';
    } else {
      this.wordCountTarget.min = 1000;
      this.wordCountLabelTarget.innerText = 'Word Count (Minimum: 1000, Increments of: 500)';
      if (!this.writingTiersTarget.querySelector('option[value="Finance and Technology"]')) {
        const item = this.writingTiersTarget.firstElementChild.cloneNode(true);
        item.innerText = 'Finance and Technology';
        item.value = 'Finance and Technology';
        this.writingTiersTarget.append(item);
      }
    }
  }
}
