import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "declineForm", "declineTeam", "approveTeam", "declineOrder", "leaveTeam", "priority", "order"]

  directToURL(event) {
    var url = event.target.dataset.url;
    window.location = url;
  }

  confirmDeleteHireMember(event) {
    var declineTarget = this.getForm(event.target);
    if (confirm("Are you sure you want to remove this user from your team?")) {
      declineTarget.submit();
    }
  }

  confirmDenyTeam(event) {
    var inviteId = event.target.dataset.invite;
    $("#hire_member_invite_id").val(inviteId);
    $("#teamInviteDenyModal").modal();
  }

  confirmApproveTeam(event) {
    var approveTarget = this.getForm(event.target);
    if (confirm("Are you sure you want to accept this team invite?")) {
      approveTarget.submit();
    }
  }

  confirmDenyOrder(event) {
    var declineTarget = this.getForm(event.target);
    if (confirm("Are you sure you want to decline this order invite?")) {
      declineTarget.submit();
    }
  }

  confirmCancelRequest(event) {
    var declineTarget = this.getFormFirstParent(event.target);
    if (confirm("Are you sure you want to cancel this request?")) {
      declineTarget.submit();
    }
  }

  confirmRemoveIllustration(event) {
    if (confirm("Are you sure you want to remove this illustration?")) {

      var orderId = event.target.dataset.order;
      var index = event.target.dataset.index;

      setupAjax();
      $.ajax({
        url: "/orders/remove_illustration",
        method: "POST",
        data: {
          order: {
            illustration_index: index,
            id: orderId
          }
        },
        dataType: "json",
        success: function () {
          location.reload();
        }
      });
    }
  }

  confirmRemoveSharedFile(event) {
    if (confirm("Are you sure you want to remove this file?")) {

      var orderId = event.target.dataset.order;
      var index = event.target.dataset.index;

      setupAjax();
      $.ajax({
        url: "/customer/access/remove/file",
        method: "POST",
        data: {
          order: {
            extra_uploads_index: index,
            id: orderId
          }
        },
        dataType: "json",
        success: function () {
          location.reload();
        }
      });
    }
  }

  openDenyOrderModal() {
    const hireInviteId = event.currentTarget.dataset.id

    $("#hire_order_invite_deny_id").val(hireInviteId);

    $("#hireOrderInviteDenyModal").modal('show');
  }

  openNotifMessageModal(event) {
    var sender = event.target.dataset.sendername;
    var message = event.target.dataset.message;

    if (sender != 'null') {
      $("#notificationSenderName").html(sender);
    } else {
      $("#notificationSenderName").html('Admin');
    }

    $("#notificationSenderMessage").html(message);
    $("#notificationMessageModal").modal('show');
  }

  openInviteMessageModal(event) {
    var message = event.target.dataset.message;

    $("#notificationSenderMessage").html(message);
    $("#inviteMessageModal").modal('show');
  }

  openStateChangeModal(event) {
    var message = event.target.dataset.message;

    $("#stateChangeMessage").html(message);
    $("#stateChangeNoteModal").modal('show');
  }

  openAssessmentNoteModal(event) {
    var message = event.target.dataset.message;

    $("#assessmentSenderMessage").html(message);
    $("#assessmentMessageModal").modal('show');
  }

  //allows customers to remove a single request
  confirmDenyRequest(event) {
    if (confirm("Are you sure you want to deny this request?")) {

      var orderId = event.target.dataset.order;
      var requestId = event.target.dataset.request;

      setupAjax();
      $.ajax({
        url: "/requests/deny_single_request",
        method: "put",
        data: {
          order: {
            id: orderId
          },
          order_request: {
            id: requestId
          }
        },
        dataType: "json",
        success: function () {
          $("#request" + requestId).addClass("d-none");
        },
        error: function() {
          alert("Unable to remove request. Please contact support");
        }
      });
    }
  }

  // Gets the form for the sequence of <i> inside of <button> clicked
  // wrapped in a form to submit
  getForm(target) {
    return target.parentElement.parentElement
  }

  getFormFirstParent(target) {
    return target.parentElement
  }

  showBeacon(event) {
    event.preventDefault();
    Beacon('toggle')
    Beacon('navigate', '/answers/')
  }

  showAskBeacon() {
    Beacon('toggle')
    Beacon('navigate', '/ask/')
  }

  confirmRemovePlagiarismFile(event) {
    if (confirm("Are you sure you want to remove this file?")) {

      var plagiarismReportId = event.target.dataset.report;
      var index = event.target.dataset.index;

      setupAjax();
      $.ajax({
        url: `/plagiarism_reports/${plagiarismReportId}/remove_file`,
        method: "delete",
        data: {
          plagiarism_report: {
            file_index: index
          }
        },
        dataType: "json",
        success: function () {
          location.reload();
        }
      });
    }
  }

  editPlagiarismReport(event) {
    let id = event.target.dataset.report;
    let orderId = event.target.dataset.order;
    let order = $("#order-" + id).text();
    let reported_by = $("#reported-by-" + id).text();
    let description = $("#description-" + id).text();

    $("#reported_by").val(reported_by.toLowerCase());
    $("#plagiarism_description").val(description);
    $("#id").val(id);
    $("#order_id").html("");
    $("#myInput").val("");
    $('#order_id').append($('<option>', {
      value: orderId,
      text: order,
      selected: true
    }));

    $("#editPlagiarismReportModal").modal();
  }

  confirmRemoveExtraFile(event) {
    if (confirm("Are you sure you want to remove this file?")) {

      var extraUploadId = event.target.dataset.upload;
      var index = event.target.dataset.index;

      setupAjax();
      $.ajax({
        url: "/files/remove/file",
        method: "DELETE",
        data: {
          extra_upload: {
            extra_uploads_index: index,
            id: extraUploadId
          }
        },
        dataType: "json",
        success: function () {
          location.reload();
        }
      });
    }
  }

  displayPriorityModal(event) {
    let reallocationActivityId = event.currentTarget.dataset.orderId
    this.orderTarget.value = reallocationActivityId
    this.priorityTarget.value = event.currentTarget.dataset.orderSelectedOption;

    $('#updatePriorityModal').modal('toggle')
  }
}
