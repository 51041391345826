import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "panel" ]

  connect() {
    console.log('conversations controller connected');
    this.newConversationSpinner = $("#new-conversation-spinner");
  }

  selectChat(event) {
    let chatId = event.detail.id

    Rails.ajax({
      type: "GET",
      url: `/conversations/${chatId}`,
      success: response => {
        this.insertChatPanel(response.body.innerHTML);
      },
      error: err => {
        console.log(`conversations_controller.js error: ${err}`)
      }
    });
  }

  selectCustomerChat(event) {
    let chatId = event.currentTarget.dataset.id
    this.insertChatPanel(spinner());
    $("#chat-list-div a").removeClass("bg-light-light");
    $(`#${chatId}`).addClass('bg-light-light');

    Rails.ajax({
      type: "GET",
      url: `/customer_conversations/${chatId}`,
      success: response => {
        this.insertChatPanel(response.body.innerHTML);
      },
      error: err => {
        console.log(`conversations_controller.js error: ${err}`)
      }
    });
  }

  insertChatPanel(content) {
    this.panelTarget.replaceChildren();
    this.panelTarget.insertAdjacentHTML('beforeend', content);

    // TODO(dtredger) dismiss modal without identifiers
    $('#createConversationModal').hide();
    $('.modal-backdrop').hide()
  }

  formSubmit(_event) {
    this.newConversationSpinner.html(spinner());
  }

  onPostSuccess(event) {
    this.newConversationSpinner.html("");
    const [data, status, xhr] = event.detail;
    this.insertChatPanel(xhr.response);
  }

  formCancel(_event) {
    this.newConversationSpinner.html("");
  }
}
