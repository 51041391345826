import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['room', 'controls', 'join', 'mute', 'cameraBtn', 'noVideo', 'localVideo', 'roomName', 'countDown', 'minutes', 'seconds', 'error', 'copy', 'loader', 'chat', 'chatBtn', 'camera', 'microphone', 'withVideo', 'withAudio', 'screenBtn', 'speaker', 'roomProgress']

  connect() {
    const { isSupported } = require('twilio-video');

    if (!isSupported) {
      this.joinTarget.innerHTML = "Your browser does not support video chat, please use the latest version of Chrome, Firefox or safari.";
    } else {
      (async () => {
        if (!navigator.userAgent.toLowerCase().includes('firefox')) {
          await navigator.permissions.query({ name: "microphone" }).then((result) => {
            if (result.state === "denied") {
              alert('You have denied microphone access. Please allow is browser settings and refresh the page.');
            }
          });
        }
        await navigator.mediaDevices.getUserMedia({ audio: true });
        let devices = await navigator.mediaDevices.enumerateDevices();
        devices.forEach(device => {
          if (device.kind === 'audioinput') {
            $("#joinButtons").removeClass('d-none');
            $("#joinButtons").addClass('d-flex');
            $("#noAudioMessage").addClass('d-none');
            if(device.deviceId == 'default') {
              $("#audioDevices").append(`<option value="${device.deviceId}" selected>${device.label}</option>`);
            } else {
              $("#audioDevices").append(`<option value="${device.deviceId}">${device.label}</option>`);
            }
          } else if (device.kind === 'audiooutput') {
            if(device.deviceId == 'default') {
              $("#audioOutputDevices").html('');
              $("#audioOutputDevices").append(`<option value="${device.deviceId}" selected>${device.label}</option>`);
            } else {
              $("#audioOutputDevices").append(`<option value="${device.deviceId}">${device.label}</option>`);
            }
          }
        });

        await navigator.mediaDevices.getUserMedia({ video: true });
        devices = await navigator.mediaDevices.enumerateDevices();
        devices.forEach(device => {
          if (device.kind === 'videoinput') {
            $("#videoDevices").append(`<option value="${device.deviceId}">${device.label}</option>`);
          }
        });
      })();
    }
  }

  async createSession(event) {
    if (!confirm('This will start a video chat and use 1 daily room. Consider using the waiting room feature instead from a DM conversation. Do you want to continue?')) {
      return;
    }    
    this.joinTarget.classList.add('d-none');
    this.joinTarget.classList.remove('d-flex');
    this.loaderTarget.classList.remove('d-none');
    $("#cameraPreview").empty();
    $("#cameraPreview").addClass('d-none');
    this.localVideoTarget.classList.remove('d-none');
    let that = this;
    setupAjax();
    $.ajax({
      url: "/video_chats",
      method: "POST",
      data: {
        chat_partner: event.target.dataset.userId
      },
      success: function(data) {
        that.errorTarget.innerHTML = '';
        that.createRoom(data.token, data.room, data.endTime);
      }, 
      error: function(data) {
        that.errorTarget.innerHTML = "<div class='text-danger my-2'>" + data.responseJSON.error + "</div>";
        that.joinTarget.classList.remove('d-none');
        that.loaderTarget.classList.add('d-none');
        $("#cameraPreview").removeClass('d-none');
        that.localVideoTarget.classList.add('d-none');
      }
    });
  }

  async joinWithCode(event) {
    this.joinSession(this.roomTarget.value);
  }

  async joinInProgress(event) {
    this.joinSession(this.roomProgressTarget.value);
  }

  async joinSession(code) {
    this.joinTarget.classList.add('d-none');
    this.joinTarget.classList.remove('d-flex');
    this.loaderTarget.classList.remove('d-none');
    $("#cameraPreview").empty();
    $("#cameraPreview").addClass('d-none');
    this.localVideoTarget.classList.remove('d-none');
    let that = this;
    setupAjax();
    $.ajax({
      url: `/video_chats/${code}`,
      method: "GET",
      success: function(data) {
        that.errorTarget.innerHTML = '';
        that.createRoom(data.token, data.room, data.endTime);
      }, 
      error: function() {
        that.errorTarget.innerHTML = "<div class='text-danger my-2'>Unable to join room. It may no longer exist.</div>";
        that.joinTarget.classList.remove('d-none');
        that.loaderTarget.classList.add('d-none');
        $("#cameraPreview").removeClass('d-none');
        that.localVideoTarget.classList.add('d-none');
      }
    });
  }

  createRoom(token, room, endTime) {
    let that = this;
    const { connect } = require('twilio-video');

    let camera = this.cameraTarget.value
    let microphone = this.microphoneTarget.value

    if(camera == '') {
      camera = false
      this.cameraBtnTarget.disabled = true;
      if ( $("#screenShare video").length ) {
        this.localVideoTarget.classList.add('no-video-screen-share');    
      } else {
        this.localVideoTarget.classList.add('no-video');    
      }
    } else {
      this.cameraBtnTarget.disabled = false;
      if ( $("#screenShare video").length ) {
        this.localVideoTarget.classList.remove('no-video-screen-share');    
      } else {
        this.localVideoTarget.classList.remove('no-video');    
      }
      camera = { deviceId: camera } 
    }

    if(microphone == '') {
      microphone = false
      this.muteTarget.disabled = true;
    } else {
      microphone = { deviceId: microphone }
      this.muteTarget.disabled = false;
    }

    if(window.previewCamera != undefined) {
      window.previewCamera.stop();
    }

    connect(token, { name: room, audio: microphone , video: camera }).then(room => {
      window.twilioRoom = room;
      
      that.copyTarget.classList.remove('invisible');
      that.roomNameTarget.innerHTML = room.name;
      that.countDownTarget.classList.remove('invisible');
      that.countDownTarget.classList.remove('d-inline');
      that.loaderTarget.classList.add('d-none');
      that.handleLocalConnectedParticipant(microphone, camera);

      window.roomTimer = (Date.parse(endTime) / 1000);
      setInterval(function() { that.makeTimer(); }, 1000);
      that.showConversation();

      // Show users already connected to the room
      room.participants.forEach(participant => {
        that.handleConnectedParticipant(participant);
      });

      // Subscribe to new users joining the room
      room.on('participantConnected', participant => {
        that.handleConnectedParticipant(participant);
        that.showConversation();
      });

      // Subscribe to user leaving room
      room.on('participantDisconnected', participant => {
        that.handleDisconnectedParticipant(participant);
      });

      if (that.withVideoTarget.value == 'False') {
        that.toggleVideo();
      }

      if (that.withAudioTarget.value == 'False') {
        that.toggleAudio();
      }

      let data = new FormData();
      data.append("id",`${window.twilioRoom.name}`);
      navigator.sendBeacon('/video_chats/start_recording', data);
    }, error => {
      that.errorTarget.innerHTML = `Unable to connect to Room: ${error.message}`;
    });    
  }

  handleLocalConnectedParticipant(microphone, camera) {
    let Video = require('twilio-video');
    let that = this;

    let cameraDevice = null;
    let microphoneDevice = null;

    if(camera == false) {
      cameraDevice = false
    } else {
      cameraDevice = camera
    }

    if(microphone == false) {
      microphoneDevice = false
    } else {
      microphoneDevice = microphone
    }

    // Show the local users video on the screen
    Video.createLocalTracks({
      audio: microphoneDevice, 
      video: cameraDevice
    }).then(function(localTracks) {
      let localMediaContainer = $('#local');
      localTracks.forEach(function(track) {
        let video = track.attach();
        video.style.transform = 'scale(-1, 1)';
        localMediaContainer.append(video);
      });
      that.controlsTarget.classList.add('d-flex');
      that.controlsTarget.classList.remove('d-none');
    });
  } 

  handleConnectedParticipant(participant) {
    $('#remote-media-div').append(`<div id="${participant.sid}" class="col-lg video-square mx-auto"></div>`)
    let that = this;

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        const track = publication.track;
        if(track.name === 'screen') {
          $('#screenShare').attr('participantId', participant.sid).append(track.attach());
          that.toggleScreenShareView(true);
        } else {
          $(`#${participant.sid}`).append(track.attach());
          that.handleTrackEnabled(track);
        }
        that.handleTrackDisabled(track);
      }
    });

    window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
      if(publication.track.name === 'screen') {
        that.toggleScreenShareView(true);
      } 
    });
  
    participant.on('trackSubscribed', track => {
      if(track.name === 'screen') {
        $('#screenShare').attr('participantId', participant.sid)
        $('#screenShare').append(track.attach());
        that.toggleScreenShareView(true);
      } else {
        const trackElement = track.attach();
        $(`#${participant.sid}`).append(trackElement);
        that.handleTrackEnabled(track);
        that.handleTrackDisabled(track);
        if(track.kind === 'video' && track.isEnabled) {
          if ( $("#screenShare video").length ) {
            $(`#${participant.sid}`).removeClass('no-video-screen-share');    
          } else {
            $(`#${participant.sid}`).removeClass('no-video');    
          }
          window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
            if (publication.track.name === 'screen') {
              that.toggleScreenShareView(true);
            }
          });
        } else if (track.kind === 'audio' && track.isEnabled) {
          $(`#${participant.sid}-mute`).remove();
          let speaker = this.speakerTarget.value

          if(speaker != '') {
            trackElement.setSinkId(speaker).then(() => {
              document.body.appendChild(trackElement);
            });
          }
        }
      }
    });

    participant.on('trackUnpublished', track => {
      if(track.trackName === 'screen') {
        that.toggleScreenShareView(false);
      }
    });
  
    $(`#${participant.sid}`).append(`<div id='${participant.sid}-name' class="participant-name d-inline">${participant.identity}</div>`)
    if ( $("#screenShare video").length ) {
      $(`#${participant.sid}`).addClass('no-video-screen-share');    
    } else {
      $(`#${participant.sid}`).addClass('no-video');    
    }
  }

  handleDisconnectedParticipant(participant) {
    if($('#screenShare').attr('participantId') == participant.sid) {
      this.toggleScreenShareView(false);
    }
    $(`#${participant.sid}`).remove();
    participant.removeAllListeners();
  }

  toggleAudio() {
    let that = this;

    window.twilioRoom.localParticipant.audioTracks.forEach(publication => {
      if (publication.track.isEnabled) {
        publication.track.disable();
        that.muteTarget.children[0].children[0].style.fill = '#FF1E49'

      } else {
        publication.track.enable();
        that.muteTarget.children[0].children[0].style.fill = '#323232'
      }
    });
  }

  toggleVideo() {
    let that = this;

    window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
      if (publication.track.name !== 'screen') {
        if (publication.track.isEnabled) {
          publication.track.disable();
          that.cameraBtnTarget.children[0].children[0].style.fill = '#FF1E49'

          window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
            if ( $("#screenShare video").length ) {
              that.localVideoTarget.classList.add('no-video-screen-share');    
            } else {
              that.localVideoTarget.classList.add('no-video');    
            }
          });
        } else {
          publication.track.enable();
          that.cameraBtnTarget.children[0].children[0].style.fill = '#323232'
          window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
            if ( $("#screenShare video").length ) {
              that.localVideoTarget.classList.remove('no-video-screen-share');    
            } else {
              that.localVideoTarget.classList.remove('no-video');    
            }
          });
        }
      } 
    });
  }

  toggleChat() {
    if (this.chatTarget.classList.contains('d-none')) {
      if(window.outerWidth > 768) {
        $('#video-chat-body').css('padding-left', '330px');
      }
      this.chatTarget.classList.remove('d-none')
    } else {
      $('#video-chat-body').css('padding-left', '0px');
      this.chatTarget.classList.add('d-none')
    }
  }

  toggleScreenShare() {
    let Video = require('twilio-video');
    let screenTrack = null;
    let screenShareInProgress = false;
    let that = this;

    window.twilioRoom.participants.forEach(participant => {
      participant.videoTracks.forEach(publication => {
        if (publication.track.name === 'screen') {
          screenShareInProgress = true;
          alert('Screen share already in progress.');
        }
      });
    });

    window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
      if (publication.track.name === 'screen') {
        screenShareInProgress = true;
        publication.track.stop();
        publication.unpublish();
        that.toggleScreenShareView(false);
        that.screenBtnTarget.classList.add('btn-success');
        that.screenBtnTarget.classList.remove('btn-danger');
        return;
      }
    });

    if(!screenShareInProgress) {
      navigator.mediaDevices.getDisplayMedia().then(stream => {
        screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], { name: 'screen' });
        window.twilioRoom.localParticipant.publishTrack(screenTrack);
        $('#screenShare').append(screenTrack.attach());
        that.toggleScreenShareView(true);
        that.screenBtnTarget.classList.add('btn-danger');
        that.screenBtnTarget.classList.remove('btn-success');
      }).catch((e) => {
          alert('Could not share the screen.' + e.message);
      });
    }
  }

  toggleScreenShareView(enable) {
    let that = this;
    if(enable) {
      that.screenBtnTarget.classList.add('btn-danger');
      that.screenBtnTarget.classList.remove('btn-success');
      $('#screenShare').removeClass('d-none');
      $('.video-square video').each(function(){
        this.classList.add('screen-share-size');
      });
      $('.video-square').each(function(){
        this.classList.remove('col-lg');
        this.classList.remove('mx-auto');
        this.classList.add('video-square-screen-share');
      });
      $('.no-video').each(function(){
        this.classList.remove('no-video');
        this.classList.add('no-video-screen-share');
      });
    } else {
      that.screenBtnTarget.classList.add('btn-success');
      that.screenBtnTarget.classList.remove('btn-danger');
      $('#screenShare').addClass('d-none');
      $('#screenShare').empty();
      $('.video-square video').each(function(){
        this.classList.remove('screen-share-size');
      });
      $('.video-square').each(function(){
        this.classList.add('col-lg');
        this.classList.add('mx-auto');
        this.classList.remove('video-square-screen-share');
      });
      $('.no-video-screen-share').each(function(){
        this.classList.remove('no-video-screen-share');
        this.classList.add('no-video');
      });
    }
  }

  disconnect() {
    if (window.twilioRoom.participants.size == 0) {
      window.twilioRoom.disconnect();
      this.completeRoom();
    } else {
      window.twilioRoom.disconnect();
    }

    window.close();
  }

  handleTrackDisabled(track) {
    if (!track.isEnabled) {
      if (track.kind === 'video') {
        window.twilioRoom.participants.forEach(participant => {
          participant.videoTracks.forEach(publication => {
            if (publication.track.sid === track.sid) {
              if ( $("#screenShare video").length ) {
                $(`#${participant.sid}`).addClass('no-video-screen-share');
              } else {
                $(`#${participant.sid}`).addClass('no-video');
              }
            }
          });
        });
      } else if (track.kind === 'audio') {
        window.twilioRoom.participants.forEach(participant => {
          participant.audioTracks.forEach(publication => {
            if (publication.track.sid === track.sid) {
              $(`#${participant.sid}-name`).append(`<div id="${participant.sid}-mute" class="d-inline ml-3"><i class="text-danger fas fa-volume-mute"></i></div>`);
            }
          });
        });
      }
    }
    track.on('disabled', () => {
      if (track.kind === 'video') {
        window.twilioRoom.participants.forEach(participant => {
          participant.videoTracks.forEach(publication => {
            if (publication.track.sid === track.sid) {
              if ( $("#screenShare video").length ) {
                $(`#${participant.sid}`).addClass('no-video-screen-share');
              } else {
                $(`#${participant.sid}`).addClass('no-video');
              }
            }
          });
        });
      } else if (track.kind === 'audio') {
        window.twilioRoom.participants.forEach(participant => {
          participant.audioTracks.forEach(publication => {
            if (publication.track.sid === track.sid) {
              $(`#${participant.sid}-name`).append(`<div id="${participant.sid}-mute" class="d-inline ml-3"><i class="text-danger fas fa-volume-mute"></i></div>`);
            }
          });
        });
      }
    });
  }

  handleTrackEnabled(track) {
    track.on('enabled', () => {
      if (track.kind === 'video') {
        window.twilioRoom.participants.forEach(participant => {
          participant.videoTracks.forEach(publication => {
            if (publication.track.sid === track.sid) {
              if ( $("#screenShare video").length ) {
                $(`#${participant.sid}`).removeClass('no-video-screen-share');
              } else {
                $(`#${participant.sid}`).removeClass('no-video');
              }
            }
          });
        });
      } else if (track.kind === 'audio') {
        window.twilioRoom.participants.forEach(participant => {
          participant.audioTracks.forEach(publication => {
            if (publication.track.sid === track.sid) {
              $(`#${participant.sid}-mute`).remove();
            }
          });
        });
      }
    });
  }

  completeRoom() {
    let data = new FormData();
    data.append("id",`${window.twilioRoom.name}`);
    navigator.sendBeacon('/video_chats/close', data);
  }

  makeTimer() {		
    let now = new Date();
    now = (Date.parse(now) / 1000);

    let timeLeft = window.roomTimer - now;

    let days = Math.floor(timeLeft / 86400); 
    let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
    let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
    let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

    if (minutes < "10") { minutes = "0" + minutes; }
    if (seconds < "10") { seconds = "0" + seconds; }

    if (timeLeft <= 0) {
      this.disconnect();
    } else {
      this.minutesTarget.innerHTML = minutes + ":";
      this.secondsTarget.innerHTML = seconds;
    }
  }

  showConversation() {
    $.ajax({
      type: "POST",
      url: "/conversations/video_chat_conversation",
      data: {
        conversation: {
          video_chat_id: window.twilioRoom.name,
        },
      },
      success: response => {
        $("#conversation_container").html(response);
      },
      error: err => {
        console.log(`conversations_controller.js error: ${err}`)
      }
    });
  }

  previewCamera(event) {
    let localMediaContainer =  $("#cameraPreview");
    localMediaContainer.empty();

    if(window.previewCamera != undefined) {
      window.previewCamera.stop();
    }
     
    let videoInput = event.target.value;
    if(videoInput == '') {
      videoInput = false;
      $("#withCamera").addClass('d-none');
    } else {
      $("#withCamera").removeClass('d-none');
      videoInput = { deviceId: videoInput }
    }
    let Video = require('twilio-video');

    Video.createLocalTracks({
      audio: false,
      video: videoInput
    }).then(function(localTracks) {
      localTracks.forEach(function(track) {
        window.previewCamera = track;
        let video = track.attach();
        video.style.transform = 'scale(-1, 1)';
        localMediaContainer.append(video);
      });
    });
  }

  changeAudio(event) {
    let audioInput = event.target.value;
    if(audioInput == '') {
      $("#withAudio").addClass('d-none');
      $("#joinButtons").addClass('d-none');
      $("#joinButtons").removeClass('d-flex');
      $("#noAudioMessage").removeClass('d-none');
    } else {
      $("#withAudio").removeClass('d-none');
      $("#joinButtons").removeClass('d-none');
      $("#joinButtons").addClass('d-flex');
      $("#noAudioMessage").addClass('d-none');
    }
  }

  createWaitingRoom(event) {
    this.loaderTarget.classList.remove('d-none');
    $("#join").addClass('d-none');
    $("#instructions").addClass('d-none');
    this.joinTarget.classList.add('d-none');
    let that = this;
    setupAjax();
    $.ajax({
      url: "/video_chat_waiting_rooms",
      method: "POST",
      data: {
        chat_partner: event.target.dataset.userId
      },
      success: function(data) {
        that.errorTarget.innerHTML = '';
        that.joinTarget.classList.add('d-none');
        $("#waitingRoom").removeClass('d-none');
        that.loaderTarget.classList.add('d-none');
        that.subscribeToWaitingRoom(data.sync, data.room, data.token);
        that.roomNameTarget.innerHTML = data.room;
        that.copyTarget.classList.remove('invisible');
      }, 
      error: function(data) {
        that.errorTarget.innerHTML = "<div class='text-danger my-2'>" + data.responseJSON.error + "</div>";
        that.joinTarget.classList.remove('d-none');
        that.loaderTarget.classList.add('d-none');
        $("#cameraPreview").removeClass('d-none');
        that.localVideoTarget.classList.add('d-none');
      }
    });
  }

  subscribeToWaitingRoom(sync, room, token) {
    let that = this;
    let syncClient = new Twilio.Sync.Client(sync);
    syncClient.document(room).then(function (document) {
      document.on("updated",function(data) {
        that.joinTarget.classList.add('d-none');
        that.joinTarget.classList.remove('d-flex');
        that.loaderTarget.classList.remove('d-none');
        $("#cameraPreview").empty();
        $("#cameraPreview").addClass('d-none');
        that.localVideoTarget.classList.remove('d-none');
        $("#waitingRoom").addClass('d-none');
        that.createRoom(token, room, data.data.endTime);
        document.close();
      });
      syncClient.on("tokenExpired", function() {
        $("#waitingRoom").html('<div class="text-danger my-2">Room Has Timed Out.</div>');
        document.close();
      });
    });
  }
}
