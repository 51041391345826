import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropDown", "input" ]

  filterDropDown() {
    var filter = this.inputTarget.value.toUpperCase();
    var dropDown = this.dropDownTarget;

    if (filter.length <= 2) {
      dropDown.options.length = 0;

      var opt = document.createElement("option");
      opt.disabled = true;
      opt.innerHTML = "Enter at least 3 characters to search";
      dropDown.appendChild(opt);

      return;
    }

    setupAjax();
    $.ajax({
      url: "/orders/filter_customers",
      method: "GET",
      data: {
        customer_search: {
          filter_by: filter
        }
      },
      dataType: "json",
      success: function (data) {
        dropDown.options.length = 0;

        if (data.customers.length === 0) {
          var opt = document.createElement("option");
          opt.disabled = true;
          opt.innerHTML = "No Customer Found";
          dropDown.appendChild(opt);
        } else {
          data.customers.forEach (function (option) {
            var opt = document.createElement("option");
            opt.value = option[3];
            opt.innerHTML = option[0] + " " + option[1] + " - " + option[2];
            dropDown.appendChild(opt);
          });
        }
      }
    });
  }

  filterOrderDropDown() {
    var filter, options, dropDown;
    dropDown =  this.dropDownTarget
    filter = this.inputTarget.value.toUpperCase();

    // Only Search when at least 3 numbers are entered
    if(filter.replace(/[^0-9]/g, '').length <= 2) {
      return;
    }
    setupAjax();
    $.ajax({
      url: "/orders/filter_orders",
      method: "GET",
      data: {
        order_search: {
          filter_by: filter
        }
      },
      dataType: "json",
      success: function (data) {
        options = data.orders
        dropDown.innerHTML = "";
        if (options.length === 0) {
          var opt = document.createElement("option");
          opt.disabled = true;
          opt.innerHTML = "No Orders Found";
          dropDown.appendChild(opt);
          return
        }
        options.forEach (function (option) {
          var opt = document.createElement("option");
          opt.value = option[2];
          if(option[1] == null) {
            opt.innerHTML = `Order ${option[0]}`;
          } else {
            opt.innerHTML = `Order ${option[0]} Item ${option[1]}`;
          }
          dropDown.appendChild(opt);
        });
      }
    });
  }

  filterEmployeeDropDown() {
    var filter = this.inputTarget.value.toUpperCase();
    var dropDown = this.dropDownTarget;

    if (filter.length <= 2) {
      dropDown.options.length = 0;

      var opt = document.createElement("option");
      opt.disabled = true;
      opt.innerHTML = "Enter at least 3 characters to search";
      dropDown.appendChild(opt);

      return;
    }

    setupAjax();
    $.ajax({
      url: "/employees/filter_employees",
      method: "GET",
      data: {
        general: {
          filter_by: filter
        }
      },
      dataType: "json",
      success: function (data) {
        dropDown.options.length = 0;

        if (data.employees.length === 0) {
          var opt = document.createElement("option");
          opt.disabled = true;
          opt.innerHTML = "No Employees Found";
          dropDown.appendChild(opt);
        } else {
          data.employees.forEach (function (option) {
            var opt = document.createElement("option");
            opt.value = option[5];
            let pen_first_name = option[2] || '';
            let pen_middle_name = option[3] || '';
            let pen_last_name = option[4] || '';
            let email = option[6] || '';
            let position = option[7] || '';

            var nameString = `${option[0]} ${option[1]}`
            var penNameString = `(${pen_first_name} ${pen_middle_name} ${pen_last_name})`
            var endingString = [email, position].filter(Boolean).join(' - ')

            if (endingString.length > 0) {
              endingString = `|| ${endingString}`
            }

            opt.innerHTML = [nameString, `${pen_first_name ? penNameString : ''}`, endingString].filter(Boolean).join(' ')
            dropDown.appendChild(opt);
          });
        }
      }
    });
  }
}
